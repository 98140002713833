import { Component, Input } from '@angular/core';
import { SelectionFilterTemplate } from '../../filter-template/selection-filter-template/selection-filter-template.model';
import { SelectionFilter } from './selection-filter.model';

@Component({
  selector: 'ap-selection-filter-item',
  templateUrl: './selection-filter-item.component.html',
  styleUrls: ['./selection-filter-item.component.css']
})
export class SelectionFilterItemComponent {

  @Input() filter: SelectionFilter;

  constructor() { }

  getLabel(): string {
    const template = <SelectionFilterTemplate> this.filter.filterTemplate;
    if (this.filter.value.includes(' ')) {
      return this.filter.value;
    }
    return template.options.find(o => (o.value === this.filter.value)).label;
  }

}
