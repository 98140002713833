import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AccessMediaFilter } from "../../filter-item/access-media-filter-item/access-media-filter.model";
import cloneDeep from 'lodash.clonedeep';

@Component({
    selector: 'ap-access-media-filter-template',
    templateUrl: './access-media-filter-template.component.html',
    styleUrls: ['./access-media-filter-template.component.css']
})
export class AccessMediaFilterTemplateComponent {

    @Input()
    get filter(): AccessMediaFilter { return this._filter; }
    set filter(filter: AccessMediaFilter) {
        this._filter = filter;
        this.filterForm.setValue({value: filter.value});
    }

    _filter: AccessMediaFilter;
    @Output() addFilter = new EventEmitter<AccessMediaFilter>();
    filterForm: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder
    ) {
        this.filterForm = this.formBuilder.group({
            value: ['', Validators.required]
          });
    }

    onSubmit() {
        if (this.filterForm.valid) {
          this._filter.value = this.filterForm.value.value;
          this.addFilter.emit(cloneDeep(this._filter));
        }
      }
}
