import { Component, HostListener, Input } from '@angular/core';
import { FilterData } from '../filterData';
import { Filter } from '@app/core/services/filter/model/filter.model';
import { FilterLoadOverlayComponent, FilterLoadOverlayData } from './filter-load-overlay/filter-load-overlay.component';
import { OverlayService } from '../../overlay/overlay.service';
import { AbstractFilter } from '../filter-item/abstract-filter.model';

@Component({
  selector: 'ap-filter-load',
  templateUrl: './filter-load.component.html',
  styleUrls: ['./filter-load.component.css']
})
export class FilterLoadComponent {

  @Input() filterData: FilterData;

  constructor (private overlayService: OverlayService) { }

  /**
   * Remove all filter with the keyboard shortcut alt + q
   *
   * @param e KeyboardEvent
   */
   @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.altKey && e.code === 'KeyS') {
      if (this.overlayService.isOpen()) {
        this.overlayService.close();
      } else {
        this.loadFilters();
      }
    }
  }

  loadFilters() {
    const data: FilterLoadOverlayData = new FilterLoadOverlayData(this.filterData.name);
    data.selectFilter.subscribe((filter: Filter) => {
      this.filterData.decode(filter.encode());
      this.addOperatorToFilters(this.filterData.filters);
      this.filterData.onChange$.next(this.filterData.filters);
      this.overlayService.close();
    });

    this.overlayService.display(FilterLoadOverlayComponent, data);
  }

  /**
   * This method is used to add operator to filter using filter template criterion
   * @param filters
   */
  addOperatorToFilters(filters: AbstractFilter[]) {
    filters.map((filter: any) => {
      if (!filter.operator || filter.operator === '') {
        filter.operator = filter.filterTemplate.criterion[0];
      }
    });
  }
}
