import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextFilter } from '../../filter-item/text-filter-item/text-filter.model';
import cloneDeep from 'lodash.clonedeep';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FilterOperatorEnum } from '@app/core/services/commons/enum/filter-operator.enum';

@Component({
  selector: 'ap-text-filter-template',
  templateUrl: './text-filter-template.component.html',
  styleUrls: ['./text-filter-template.component.css']
})
export class TextFilterTemplateComponent {

  @Input()
  get filter(): TextFilter { return this._filter; }
  set filter(filter: TextFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: filter.value, operator: filter.operator});
  }
  _filter: TextFilter;

  @Output() addFilter = new EventEmitter<TextFilter>();

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.formBuilder.group({
      value: ['', Validators.required],
      operator: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = this.filterForm.value.value;
      this._filter.operator = this.filterForm.value.operator;
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

  shouldShowInputField(): boolean {
    // Check operator value of filter. When operator is empty, we will hide input field
    if (this.filterForm.value.operator === FilterOperatorEnum.EMPTY) {
      // Set value field with blank string when filter operator is empty
      this.filterForm.patchValue({value: ' '});
      return false;
    }
    return true;
  }

}
