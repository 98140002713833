import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ap-reload-callout',
  templateUrl: './reload-callout.component.html',
  styleUrls: ['./reload-callout.component.css']
})
export class ReloadCalloutComponent {

  @Input() show: boolean;
  @Output() reload = new EventEmitter<void>();

  constructor() { }

  action() {
    this.reload.emit(null);
  }

}
