import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFilter } from '../filter-item/abstract-filter.model';

@Component({
  selector: 'ap-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent {

  @Input() filters: Array<AbstractFilter>;

  @Output() add = new EventEmitter<void>();

  @Output() remove = new EventEmitter<number>();

  @Output() edit = new EventEmitter<number>();

  @Output() disable = new EventEmitter<number>();

  constructor() { }

  onAdd() {
    this.add.emit();
  }

  onEdit(filter: number) {
    this.edit.emit(filter);
  }

  onDisable(filterId: number) {
    this.disable.emit(filterId);
  }

  onRemove(filter: number) {
    this.remove.emit(filter);
  }

}
