import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SipsFilter } from '@app/shared/components/filter/filter-item/sips-filter-item/sips-filter.model';

@Component({
  selector: 'ap-table-sips-filter',
  templateUrl: './table-sips-filter.component.html',
  styleUrls: ['./table-sips-filter.component.css']
})
export class TableSipsFilterComponent {

  @Input() filter: SipsFilter;

  @Output() save = new EventEmitter<SipsFilter>();

  constructor() { }

  onEnter() {
    this.save.emit(this.filter);
  }
}
