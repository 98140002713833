import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateFilter } from '../../filter-item/date-filter-item/date-filter.model';
import cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';

@Component({
  selector: 'ap-date-filter-template',
  templateUrl: './date-filter-template.component.html',
  styleUrls: ['./date-filter-template.component.css']
})
export class DateFilterTemplateComponent {

  @Input()
  get filter(): DateFilter { return this._filter; }
  set filter(filter: DateFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: moment(filter.value).format('YYYY-MM-DD')});
  }
  _filter: DateFilter;

  @Output() addFilter = new EventEmitter<DateFilter>();

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.formBuilder.group({
      value: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = new Date(this.filterForm.value.value);
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

}
