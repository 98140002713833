import { Component } from '@angular/core';

@Component({
  selector: 'ap-sips-message',
  templateUrl: './sips-message.component.html',
  styleUrls: ['./sips-message.component.css']
})
export class SipsMessageComponent {

  constructor() { }

}
