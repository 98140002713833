import { Component, ContentChild, Output, Input, TemplateRef, EventEmitter } from '@angular/core';
import { Pagination } from '../pagination/pagination.model';

/**
 * Table component. This component provide element to build a table with loading and empty message.
 *
 * @example
 * <pre><code>
 *   <ap-table [data]="filteredSettlements"
 *             [loading]="loading"
 *             [pagination]="pagination"
 *             (changeSize)="filterByPaginationSize($event)"
 *             (changePage)="filterByPagination($event)">
 *     <!-- Header -->
 *     <ng-template #tableHeader>
 *       <th ap-sort="reference" [sort]="sort" (changeSort)="filterBySort($event)">
 *         {{ 'SETTLEMENTS.TABLE.COLUMNS.REFERENCE' | translate }}
 *       </th>
 *       <th ap-sort="createAt" [sort]="sort" (changeSort)="filterBySort($event)">
 *         {{ 'SETTLEMENTS.TABLE.COLUMNS.CREATED_AT' | translate }}
 *       </th>
 *     </ng-template>
 *     <!-- Row -->
 *     <ng-template #tableBody let-element>
 *       <th scope="row">{{element.reference}}</th>
 *       <td>{{element.createdAt | localizedDate: 'mediumDate' }}</td>
 *     </ng-template>
 *   </ap-table>
 * </code></pre>
 */
@Component({
  selector: 'ap-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {

  @ContentChild('tableHeader') lineHeader: TemplateRef<any>;
  @ContentChild('tableFilter') lineFilter: TemplateRef<any>;
  @ContentChild('tableBody') lineBody: TemplateRef<any>;
  @ContentChild('tableBulkAction') lineBulkAction: TemplateRef<any>;

  @Input() loading = false;
  @Input() data = [];
  @Input() pagination: Pagination;
  @Input() dark = false;
  @Input() grouped = false;
  @Input() checked = new Map<string, boolean>();

  @Output() changePage = new EventEmitter<Number>();
  @Output() changeSize = new EventEmitter<Number>();
  @Output() selectAllRows = new EventEmitter<Boolean>();

  checkedAll = false;

  get shouldShowBulkActionHeader(): boolean {
    return this.grouped && (Array.from(this.checked.values()).filter(b => b).length > 0);
  }

  constructor() {}

  onChangePage(page: Number) {
    this.changePage.emit(page);
  }

  onChangeSize(size: Number) {
    this.changeSize.emit(size);
  }

  checkAllRows() {
    this.checkedAll = !this.checkedAll;
    this.selectAllRows.emit(this.checkedAll);
  }

  selectRow(element) {
    this.checked.set(element.poi, !this.checked.get(element.poi));
  }
}
