import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AppAuthGuard } from './core/guards/authguard';
import { StatisticComponent } from '@app/modules/statistic/statistic.component';
import { StatisticTravelComponent } from '@app/modules/statistic/statistic-travel/statistic-travel.component';
import { AppInternalSupportGuard } from './core/guards/app-internal-support-guard';
import { PermissionEnum } from './core/services/administrator/models/enum/permission';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('@app/modules/home/home.module').then(m => m.HomeModule) },
  { path: 'users', loadChildren: () => import('@app/modules/users/users.module').then(m => m.UsersModule) },
  { path: 'customerrequests', canActivate: [AppInternalSupportGuard], loadChildren: () => import('@app/modules/customer-requests/customer-requests.module').then(m => m.CustomerRequestsModule) },
  { path: 'administrators', loadChildren: () => import('@app/modules/administrators/administrators.module').then(m => m.AdministratorsModule) },
  { path: 'accessmedias', loadChildren: () => import('@app/modules/access-medias/access-medias.module').then(m => m.AccessMediasModule) },
  { path: 'error/:code', loadChildren: () => import('@app/modules/errors/errors.module').then(m => m.ErrorsModule) },
  { path: 'statistic', component: StatisticComponent, canActivate: [AppAuthGuard], data: { permissions: [PermissionEnum.ACCESS_STATISTICS, PermissionEnum.ACCESS_MYCOMPANY_STATISTICS] }  },
  { path: 'statistic/statistic-travel', component: StatisticTravelComponent, canActivate: [AppAuthGuard], data: { permissions: [PermissionEnum.ACCESS_STATISTICS, PermissionEnum.ACCESS_MYCOMPANY_STATISTICS] }  },
  { path: 'devices', loadChildren: () => import('@app/modules/devices/devices.module').then(m => m.DevicesModule) },
  { path: 'extra', loadChildren: () => import('@app/modules/extra/extra.module').then(m => m.ExtraModule) },
  { path: 'devices', loadChildren: () => import('@app/modules/devices/devices.module').then(m => m.DevicesModule) },
  { path: 'tariffication', loadChildren: () => import('@app/modules/tariffication/tariffication.module').then(m => m.TarifficationModule) },
  { path: 'tools', loadChildren: () => import('@app/modules/tools/tools.module').then(m => m.ToolsModule) },
  { path: 'maintenance', loadChildren:() => import('@app/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {}
