import { Component } from '@angular/core';

@Component({
  selector: 'ap-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.css']
})
export class PageToolbarComponent {

  constructor() {}

}
