import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'; // Assuming you are using ngx-toastr
import { TranslateService } from '@ngx-translate/core'; // Assuming you are using ngx-translate
import { environment } from '@env/environment';

@Injectable({
providedIn: 'root'
})
export class StatisticService {

private apiUrl = environment.admin.api.mobilityEngine + '/api/es-feeder/v1/taps/statistics';

constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  fetchStatistics(fromDate: string, toDate: string, selectedRange: string): Observable<{ [key: string]: { [key: string]: number } }> {
    const url = `${this.apiUrl}/${fromDate}/${toDate}/${selectedRange}`;

    return this.http.get<{ [key: string]: { [key: string]: number } }>(url).pipe(
      catchError((error) => {
        if (error.status === 403) {
          // Redirect to the 403 error page
          this.router.navigate(['/error/403']);
        } else {
          console.error('Error fetching data from API:', error);
          this.toastr.error(this.translateService.instant('STATISTIC-TRAVEL.FETCH_STATISTIC_ERROR'));
        }
        return throwError(error); // Re-throw the error for additional handling if needed
      })
    );
  }
}
