import { Component, Input, OnInit } from '@angular/core';
import { DateTimeFilter } from './datetime-filter.model';

@Component({
  selector: 'ap-datetime-filter-item',
  templateUrl: './datetime-filter-item.component.html'
})
export class DateTimeFilterItemComponent {

  @Input() filter: DateTimeFilter;

  constructor() { }

}
