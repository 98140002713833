import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entry } from '@app/shared/components/autocomplete/autocomplete.component';
import cloneDeep from 'lodash.clonedeep';
import { AutocompleteFilter } from '../../filter-item/autocomplete-filter-item/autocomplete-filter.model';
import { FilterOperatorEnum } from '@app/core/services/commons/enum/filter-operator.enum';

@Component({
  selector: 'ap-autocomplete-filter-template',
  templateUrl: './autocomplete-filter-template.component.html',
  styleUrls: ['./autocomplete-filter-template.component.css']
})
export class AutocompleteFilterTemplateComponent {
  value: Entry<string, string>;
  operator: FilterOperatorEnum;

  @Input()
  get filter(): AutocompleteFilter { return this._filter; }
  set filter(filter: AutocompleteFilter) {
    this._filter = filter;
    this.value = this._filter.value;
    this.operator = this._filter.operator;
  }
  _filter: AutocompleteFilter;

  @Output() addFilter = new EventEmitter<AutocompleteFilter>();

  constructor() {
  }

  onChange(operatorValue) {
    this.operator = operatorValue;
  }

  onSelect(entry: Entry<string, string>) {
    this.value = entry;
  }

  onClear() {
    this.value = null;
  }

  onSubmit() {
    this._filter.value = this.value;
    this._filter.operator = this.operator;
    this.addFilter.emit(cloneDeep(this._filter));
  }

  shouldShowAutocompleteField(): boolean {
    if (this.operator === FilterOperatorEnum.EMPTY) {
      this.value = {key: ' ', value: ' '};
      return false;
    }
    return true;
  }

}
