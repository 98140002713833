import { Component, Input } from '@angular/core';
import { TextFilter } from './text-filter.model';

@Component({
  selector: 'ap-text-filter-item',
  templateUrl: './text-filter-item.component.html',
  styleUrls: ['./text-filter-item.component.css']
})
export class TextFilterItemComponent {

  @Input() filter: TextFilter;

  constructor() { }

}
