import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    constructor(private httpClient: HttpClient) { }

    addTenantHeader(request: HttpRequest<any>) {
        const headers: HttpHeaders = request.headers;
        if (environment.admin.multitenant.enabled) {
            return headers.append('X-TENANT', environment.admin.multitenant.tenant);
        }
        return headers;
    }

    getPropertiesByTenant(baseUrl: string): Observable<any> {
        return this.httpClient.get<any>(baseUrl);
    }
}
