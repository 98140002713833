import { Component } from '@angular/core';

@Component({
  selector: 'ap-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.css']
})
export class CardBodyComponent {

  constructor() {}

}
