import { Component, Input } from '@angular/core';
import { DateFilter } from './date-filter.model';

@Component({
  selector: 'ap-date-filter-item',
  templateUrl: './date-filter-item.component.html'
})
export class DateFilterItemComponent {

  @Input() filter: DateFilter;

  constructor() { }

}
