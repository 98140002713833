import { Component, Input } from '@angular/core';

@Component({
  selector: 'ap-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.css']
})
export class StatusLabelComponent {

  @Input()
  status: string;

  constructor() { }

}
