import { Component, Input, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash.clonedeep';
import { AbstractFilter } from '../filter-item/abstract-filter.model';

@Component({
  selector: 'ap-filter-edit',
  templateUrl: './filter-edit.component.html',
  styleUrls: ['./filter-edit.component.css']
})
export class FilterEditComponent {

  @Input()
  get filter(): AbstractFilter {
    return this._filter;
  }
  set filter(filter: AbstractFilter) {
    this._filter = cloneDeep(filter);
  }
  _filter: AbstractFilter;

  @Output() validate = new EventEmitter<AbstractFilter>();

  constructor() { }

  onValidate(filter: AbstractFilter) {
    this.validate.emit(filter);
  }
}
