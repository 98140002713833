import { Component, Output, Input, HostListener, ViewEncapsulation, HostBinding } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Sort } from './sort.model';

/**
 * Sort component. This component works with th[ap-sort]. Provide event (Sort Object) if user click on the field.
 *
 * @example
 * <pre><code>
 *  <th ap-sort="field"></th>
 * </code></pre>
 */

@Component({
  selector: '[ap-sort]',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class SortComponent {

  @Output() changeSort = new EventEmitter<Sort>();
  @Input('ap-sort') name: string;

  @Input()
  get sort(): Sort {
    return this._sort;
  }
  set sort(sort: Sort) {
    if (sort && sort.field === this.name) {
      this._sort = sort;
      this.updateDisplay();
    } else {
      this.reset();
    }
  }
  private _sort: Sort;

  @HostBinding('class.sorting_asc') isSortingAsc = false;
  @HostBinding('class.sorting_desc') isSortingDesc = false;
  @HostBinding('class.sorting') isSorting = true;

  constructor() { }

  @HostListener('click', ['$event.target'])
  change() {
    this._sort = {
      field: this.name,
      order: this._sort && this._sort.order === 'ASC' ? 'DESC' : 'ASC'
    };
    this.updateDisplay();
    this.changeSort.emit(new Sort(this._sort.field, this._sort.order));
  }

  reset() {
    this._sort = null;
    this.updateDisplay();
  }

  private updateDisplay() {
    if (this._sort) {
      if (this._sort.order === 'ASC') {
        this.isSortingAsc = true;
        this.isSortingDesc = false;
      } else {
        this.isSortingAsc = false;
        this.isSortingDesc = true;
      }
    } else {
      this.isSortingAsc = false;
      this.isSortingDesc = false;
    }
  }
}
