import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FilterState } from '../filter/filter-state-store/filter-state';
import { selectFilterState } from '../filter/filter-state-store/filter-state.selector';

@Component({
  selector: 'ap-back-to-list-button',
  templateUrl: './back-to-list-button.component.html',
})
export class BackToListButtonComponent implements OnDestroy {

  stateSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<FilterState>
  ) { }

  backPage() {
    const state = this.store.select(selectFilterState);
    this.stateSubscription = state.subscribe(result => {
      if (result && result.filterInfo) {
        this.router.navigate([result.filterInfo.fromPage], {
          queryParams: {
            filter: result.filterInfo.encodedFilter,
            pageNumber: result.filterInfo.pageNumber,
            pageSize: result.filterInfo.pageSize,
            sortElement: result.filterInfo.sort.field,
            sortOrder: result.filterInfo.sort.order,
            genericParameterNames: result.filterInfo.fromPage === 'devices' ? result.filterInfo.genericParameterNames : null
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

}
