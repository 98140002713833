import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface ActionTimeline {
  label: string,
  event: string
}

export interface TimelineModel {
  tag: string,
  title: string,
  byline: string,
  excerpt: string,
  action?: ActionTimeline
}

@Component({
  selector: 'ap-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent {

  @Input()
  timelines: TimelineModel[];

  @Output() clickEvent = new EventEmitter<any>();

  onClick (event: string, timeline: TimelineModel) {
    this.clickEvent.emit([event, timeline]);
  }

  constructor() { }

}
