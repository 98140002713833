import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ap-radio',
  template:   `
    <div class="iradio_flat-green" [ngClass]="{'checked': checked}" (click)="selectItem()">
      <input type="radio" class="flat" style="position: absolute; opacity: 0;" title="{{ ariaLabel }}">
      <ins class="iCheck-helper" style="display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 0px none; opacity: 0;"></ins>
    </div>`
})
export class RadioComponent {

  @Input() checked: boolean;
  @Input() ariaLabel = '';
  @Output() select = new EventEmitter<boolean>();

  constructor() { }

  selectItem(): void {
    this.select.emit(this.checked);
  }

}
