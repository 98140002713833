import { Component, Input } from '@angular/core';

@Component({
  selector: 'ap-export-data',
  templateUrl: './export-data.component.html'
})
export class ExportDataComponent {

  @Input() exportType: 'SETTLEMENT' | 'DEVICES' | 'ALARMS';

  constructor() { }
}
