import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionFilter } from '@app/shared/components/filter/filter-item/selection-filter-item/selection-filter.model';

@Component({
  selector: 'ap-table-select-filter',
  templateUrl: './table-select-filter.component.html',
  styleUrls: ['./table-select-filter.component.css']
})
export class TableSelectFilterComponent {

  @Input() filter: SelectionFilter;
  @Input() noValueOption = true;
  @Output() save = new EventEmitter<SelectionFilter>();

  constructor() { }

  onChange() {
    this.save.emit(this.filter);
  }

}
