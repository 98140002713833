import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface SaccGatewayModalModel {
  title?: string;
  message?: string;
  confirmButton?: string;
}

@Component({
  selector: 'app-sacc-gateway-modal',
  templateUrl: './sacc-gateway-modal.component.html',
  styleUrls: ['./sacc-gateway-modal.component.css']
})
export class SaccGatewayModalComponent extends SimpleModalComponent<SaccGatewayModalModel, boolean> {

  title: string;
  message: string;
  confirmButton: string;

  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

}
