import { Component } from '@angular/core';

@Component({
  selector: 'ap-media-event-title',
  templateUrl: './media-event-title.component.html',
  styleUrls: ['./media-event-title.component.css']
})
export class MediaEventTitleComponent {

  constructor() { }

}
