import { AfterViewInit, Component, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'ap-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements AfterViewInit {

  @Input() initialViewState = {
    lat: 0,
    lng: 0,
    zoom: 0
  };

  @Input() marker = {
    lat: 0,
    lng: 0,
    popupLabel: ''
  };

  constructor() {}

  ngAfterViewInit() {
    const mapContainer = <HTMLDivElement> document.getElementById('map');

    const iconRetinaUrl = 'assets/marker-icon-2x.png';
    const iconUrl = 'assets/marker-icon.png';
    const shadowUrl = 'assets/marker-shadow.png';
    const iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;

    const map = this.initializeMapViewState(mapContainer);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker([this.marker.lat, this.marker.lng]).addTo(map)
      .bindPopup(this.marker.popupLabel)
      .openPopup();
  }

  private initializeMapViewState(mapContainer: HTMLDivElement) {
    return new L.Map(mapContainer).setView(
      [this.initialViewState.lat, this.initialViewState.lng],
      this.initialViewState.zoom);
  }

}

