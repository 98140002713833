import { Component } from '@angular/core';

/**
 * Form step component. This component works with li[ap-step] component to include steps.
 *
 * @example
 * <pre><code>
 * <ap-steps>
 *   <li ap-step [activeStep]="1" [rel]="1" [value]="'x'" [title]="'Step 1'" [subtitle]="'Step description'" (click)="fn()"></li>
 *   <li ap-step [activeStep]="1" [rel]="2" [value]="'x+1'" [title]="'Step 2'" [subtitle]="'Step description'" (click)="fn()"></li>
 *   <li ap-step [activeStep]="1" [rel]="3" [value]="'x+2'" [title]="'Step 3'" [subtitle]="'Step description'" (click)="fn()"></li>
 * </ap-steps>
 * </code></pre>
 */

@Component({
  selector: 'ap-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent {
  constructor() {}

}
