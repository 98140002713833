import { Component, ContentChild, TemplateRef, Input } from '@angular/core';
import { CheckListItemDirective } from './checklist-item/checklist-item.directive';


@Component({
  selector: 'ap-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent {

  @Input() items: any[];

  @ContentChild(CheckListItemDirective, { read: TemplateRef }) itemTemplate: TemplateRef<any>;

  constructor() { }

}
