import { Component } from '@angular/core';

@Component({
  selector: 'ap-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent {

  constructor() { }

}
