<ap-page>
  <ap-page-title>{{ 'STATISTIC-TRAVEL.LIST.TITLE' | translate }}</ap-page-title>
  <ap-page-content>
    <ap-panel>
      <ap-panel-content>
        <form class="form-horizontal" style="padding-top: 10px; padding-bottom: 14px;" *ngIf="currentRange" aria-label="statistic form">
          <div class="row">
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label id="fromDateLabel">{{ 'STATISTIC-TRAVEL.FROM' | translate }}</label>
              <input type="date" name="from" class="form-control" [(ngModel)]="fromDate" (change)="onChangeFrom($event.target.value)" step="1"/>
            </div>
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label id="toDateLabel">{{ 'STATISTIC-TRAVEL.TO' | translate }}</label>
              <input type="date" name="to" class="form-control" [(ngModel)]="toDate" (change)="onChangeTo($event.target.value)" step="1"/>
            </div>
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label for="selectRange" id="selectRangeLabel">{{ 'STATISTIC-TRAVEL.SELECT' | translate }}</label>
              <select id="selectRange" name="selectRange" class="form-control" [(ngModel)]="selectedRange">
                <option [value]="'DAY'">{{ 'STATISTIC-TRAVEL.DAY' | translate }}</option>
                <option [value]="'MONTH'">{{ 'STATISTIC-TRAVEL.MONTH' | translate }}</option>
              </select>
            </div>
            <div class="col-md-offset-0 col-lg-2 col-sm-2 col-xs-2">
              <span class="stats-label"> &nbsp; </span>
              <button type="button" id="apply" class="btn btn-success form-control" name="apply" (click)="applyFilters()">{{ 'ACTIONS.APPLY' | translate }}</button>
            </div>
          </div>
          <!-- Error message display -->
          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ 'STATISTIC-TRAVEL.DATE_RANGE_TOO_LONG' | translate }}
          </div>
        </form>

        <!-- Loading Spinner -->
        <div *ngIf="isLoading" class="text-center">
          <div class="spinner"></div> <!-- Spinner div -->
        </div>

        <!-- Table to Display Statistics -->
        <div *ngIf="!isLoading && statisticsData">
          <table class="table">
            <thead>
            <tr>
              <th (click)="sortByDate()" style="cursor:pointer;">
                {{ 'STATISTIC-TRAVEL.DATE' | translate }}
                <span *ngIf="sortDirections['date'] === 'asc'">▲</span>
                <span *ngIf="sortDirections['date'] === 'desc'">▼</span>
              </th>
              <th *ngFor="let travelType of getTravelTypes()" (click)="sortBy(travelType)" style="cursor:pointer;">
                {{ travelType }}
                <ng-container *ngIf="travelType == 1; else multipleTravels">
                  {{ 'STATISTIC-TRAVEL.TRAVEL' | translate }}
                </ng-container>
                <ng-template #multipleTravels>
                  {{ 'STATISTIC-TRAVEL.TRAVELS' | translate }}
                </ng-template>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let currentRecord of getCurrentRecords()">
              <td>{{ currentRecord }}</td>
              <td *ngFor="let type of getTravelTypes()">
                <ng-container *ngIf="getTapRangeData(currentRecord) as tapRange">
                  {{ tapRange ? tapRange[mapTravelType(type)] || 0 : "No Data" }}
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="getCurrentRecords().length === 0">
              <td colspan="{{ getTravelTypes().length + 1 }}">{{ 'STATISTIC-TRAVEL.NO_RECORD' | translate }}</td>
            </tr>
            </tbody>
          </table>

          <!-- Pagination Controls -->
          <nav aria-label="Page navigation" *ngIf="totalItems > itemsPerPage">
            <ul class="pagination" style="cursor:pointer;">
              <li [class.disabled]="currentPage === 1">
                <a (click)="changePage(currentPage - 1)" aria-label="Previous">«</a>
              </li>
              <li *ngFor="let page of [].constructor(totalPages); let i = index"
                  [class.active]="i + 1 === currentPage">
                <a (click)="changePage(i + 1)">{{ i + 1 }}</a>
              </li>
              <li [class.disabled]="currentPage === totalPages">
                <a (click)="changePage(currentPage + 1)" aria-label="Next">»</a>
              </li>
            </ul>
          </nav>
        </div>
      </ap-panel-content>
    </ap-panel>
  </ap-page-content>
</ap-page>
