import { Component } from '@angular/core';

/**
 * Navigation bar tabulation component.
 *
 * @see NavBarComponent
 */
@Component({
  selector: 'li[ap-nav-bar-tab]',
  templateUrl: './nav-bar-tab.component.html',
  styleUrls: ['./nav-bar-tab.component.css']
})
export class NavBarTabComponent {

  constructor() {}

}
