import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { StatisticService } from '@app/core/services/statistic/statistic.service';

interface StatisticData {
intervalKey: string;
tapRangeMap: { [key: string]: number };
}

@Component({
selector: 'app-statistic',
templateUrl: './statistic-travel.component.html',
styleUrls: ['./statistic-travel.component.css']
})
export class StatisticTravelComponent implements OnInit {
fromDate: string;
toDate: string;
currentRange: { from: moment.Moment; to: moment.Moment } | null = null;
selectedRange: string = 'DAY';

// For holding API response
statisticsData: StatisticData[] | null = null;
sortedKeys: string[] = [];
sortDirections: { [key: string]: 'asc' | 'desc' } = {};

// Pagination properties
currentPage: number = 1;
itemsPerPage: number = 10;
totalItems: number = 0;
totalPages: number = 0;

isLoading: boolean = false;
errorMessage: string | null = null;

constructor(private statisticService: StatisticService) {}

  ngOnInit(): void {
    this.currentRange = {
      from: moment(),
      to: moment(),
    };
    this.updateDateFields();
    // Call to initialize API response
    this.fetchStatistics();
  }

  updateDateFields(): void {
    this.fromDate = this.currentRange.from.format('YYYY-MM-DD');
    this.toDate = this.currentRange.to.format('YYYY-MM-DD');
  }

  private calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  private calculateTotalItems(): void {
    this.totalItems = this.statisticsData ? this.statisticsData.length : 0;
    this.calculateTotalPages();
  }

  changePage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = page;
  }

  private fetchStatistics(): void {
    this.isLoading = true;

    this.statisticService.fetchStatistics(
      this.currentRange.from.format('YYYY-MM-DD'),
      this.currentRange.to.format('YYYY-MM-DD'),
      this.selectedRange
    ).subscribe(data => {
      this.isLoading = false;
      if (Array.isArray(data)) {
        this.statisticsData = data.map(item => ({
          intervalKey: item.intervalKey,
          tapRangeMap: item.tapRangeMap
        }));
        // Initial sorting on date after fetching data
        this.sortByDate();
      } else {
        console.error('Unexpected data shape:', data);
        this.statisticsData = null;
      }
      this.calculateTotalItems(); // Recalculate total items after fetching data
    }, error => {
      this.isLoading = false;
      console.error('Error fetching data from API:', error);
    });
  }

  // Sorting functionality for dates
  sortByDate(): void {
    if (!this.statisticsData) return;

    // Determine the current sort direction
    const currentDirection = this.sortDirections['date'] === 'asc' ? 'desc' : 'asc';
    this.sortDirections['date'] = currentDirection; // Toggle direction

    this.sortedKeys = this.statisticsData!.sort((a, b) => {
      const aDate = moment(a.intervalKey, 'DD/MM/YYYY').isValid() ? moment(a.intervalKey, 'DD/MM/YYYY') : moment(a.intervalKey, 'MM-YYYY');
      const bDate = moment(b.intervalKey, 'DD/MM/YYYY').isValid() ? moment(b.intervalKey, 'DD/MM/YYYY') : moment(b.intervalKey, 'MM-YYYY');

      // Sort based on the current direction
      return currentDirection === 'asc' ? aDate.valueOf() - bDate.valueOf() : bDate.valueOf() - aDate.valueOf();
    }).map(item => item.intervalKey);

    this.currentPage = 1; // Reset to first page after sorting
  }

  getCurrentRecords(): string[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.sortedKeys.slice(startIndex, startIndex + this.itemsPerPage);
  }

  // Apply filters and reset page
  applyFilters(): void {
    const differenceInDays = this.currentRange.to.diff(this.currentRange.from, 'days');
    if (differenceInDays > 365) {
      this.errorMessage = 'STATISTIC-TRAVEL.DATE_RANGE_TOO_LONG';
      return;
    }

    this.errorMessage = null;
    if (this.currentRange) {
      this.fetchStatistics();
      this.currentPage = 1; // Reset to first page after applying filters

      // Set sort direction to 'desc' for date
      this.sortDirections['date'] = 'desc';

      // Call sortByDate to sort the records based on the new direction
      this.sortByDate();
    } else {
      console.warn('Current range is not defined!');
    }
  }

  getTapRangeData(currentRecord: string) {
    const record = this.statisticsData?.find(r => r.intervalKey === currentRecord);
    return record ? record.tapRangeMap : null;
  }

  onChangeFrom(date: string): void {
    this.currentRange!.from = moment(date);
  }

  onChangeTo(date: string): void {
    this.currentRange!.to = moment(date);
  }

  getTravelTypes(): string[] {
    return ['1', '2', '3', '4', '5-10', '11-20', '21-35', '+35'];
  }

  private mapTravelType(type: string): string {
    switch (type) {
      case '1':
        return 'Tap1';
      case '2':
        return 'Tap2';
      case '3':
        return 'Tap3';
      case '4':
        return 'Tap4';
      case '5-10':
        return 'Tap 5-10';
      case '11-20':
        return 'Tap 11-20';
      case '21-35':
        return 'Tap 21-35';
      case '+35':
        return 'Tap 35+';
      default:
        return '';
    }
  }
}
