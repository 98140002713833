import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash.clonedeep';
import { SelectionFilter } from '../../filter-item/selection-filter-item/selection-filter.model';
import { FilterOperatorEnum } from '@app/core/services/commons/enum/filter-operator.enum';

@Component({
  selector: 'ap-selection-filter-template',
  templateUrl: './selection-filter-template.component.html',
  styleUrls: ['./selection-filter-template.component.css']
})
export class SelectionFilterTemplateComponent {

  @Input()
  get filter(): SelectionFilter { return this._filter; }
  set filter(filter: SelectionFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: filter.value, operator: filter.operator});
  }
  _filter: SelectionFilter;

  @Output() addFilter = new EventEmitter<SelectionFilter>();

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.formBuilder.group({
      value: new UntypedFormControl('', Validators.required),
      operator: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = this.filterForm.value.value;
      this._filter.operator = this.filterForm.value.operator;
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

  shouldShowSelectField(): boolean {
    if (this.filterForm.value.operator === FilterOperatorEnum.EMPTY) {
      this.filterForm.patchValue({value: ' '});
      return false;
    }
    return true;
  }

}
