import { AfterViewInit, Component, ContentChildren, ElementRef, forwardRef, HostListener, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OptionComponent, OptOptionComponent } from './option.component';
import slugify from 'slugify';

@Component({
  selector: 'ap-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {

  currentValue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Input() ariaLabel = '';

  label: string;
  edition = false;
  term: string = '';

  @ContentChildren(OptionComponent, { descendants: true }) optionComponents: QueryList<OptionComponent>;
  @ContentChildren(OptOptionComponent, { descendants: true }) optOptionComponents: QueryList<OptOptionComponent>;

  isEmpty = false;

  constructor(private eRef: ElementRef) { }

  /**
   * Disable choice panel (edition mode) if user click outside component
   * @param targetElement Element
   */
  @HostListener('document:click', ['$event.target'])
  clickout(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (targetElement.localName !== 'button' && targetElement.localName !== 'i' && this.edition && !clickedInside) {
      this.edition = false;
    }
  }

  private subscribeToOptions() {
    this.optionComponents.forEach(option => {
      option.selectValue.subscribe((value: any) => {
        this.label = this.getCanonicalName(option);
        this.writeValue(value);
      });
    });
  }

  ngAfterViewInit() {
    this.subscribeToOptions();

    this.optionComponents.changes.subscribe(_change => {
      this.reset();
      this.subscribeToOptions();
    });

    if (this.currentValue.value) {
      const option = this.optionComponents.find(option => option.value === this.currentValue.value);
      if (option) {
        this.label = option.label;
      }
    }
  }

  /**
   * Build a label with ancestor labels.
   * @param option OptionComponent
   * @returns string
   */
  private getCanonicalName(option: OptionComponent): string {
    let opt = option.optOptionParent;
    let label = '';

    while(opt) {
      label += opt.label + ' > ';
      opt = opt.optOptionParent;
    }

    return label + option.label;
  }

  /**
   * Filter options with a slug
   */
  filter() {
    this.isEmpty = true;
    this.optionComponents.forEach(option => {
      const value = slugify(this.getCanonicalName(option), {
        lower: true,
        locale: 'fr',
      });
      const term = slugify(this.term, {
        lower: true,
        locale: 'fr',
      });

      option.isDisplayed = value.includes(term);

      if (option.isDisplayed) {
        this.isEmpty = false;
      }
    });

    this.optOptionComponents.forEach(opt => {
      opt.isDisplayed = opt.optionComponents.some(option => option.isDisplayed);
    });
  }

  /**
   * Display panel to choice value
   */
  enableEdition() {
    this.edition = true;
  }

  /**
   * Reset value & term
   */
  reset() {
    this.term = '';
    this.filter();
    this.writeValue(null);
  }

  onTouched: () => void;

  registerOnChange(fn: any): void {
    this.currentValue.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.edition = false;

    this.currentValue.next(obj);
  }

}
