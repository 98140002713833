import { Component } from '@angular/core';

@Component({
  selector: 'ap-media-event',
  templateUrl: './media-event.component.html',
  styleUrls: ['./media-event.component.css']
})

export class MediaEventComponent {

  constructor() { }

}
