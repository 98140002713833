import { Component } from '@angular/core';

/**
 * Top navigation bar component. This component works with li[ap-nav-bar-tab] component to include tabs.
 *
 * @example
 * <pre><code>
 * <ap-nav-bar>
 *   <li ap-nav-bar-tab (click)="fn() class="active">{{ 'MENU.DEVICES.INFO_TAB' | translate }}</li>
 *   <li ap-nav-bar-tab (click)="fn()><i class="fa fa-pencil"></i> {{ 'MENU.DEVICES.CONFIG_TAB' | translate }}</li>
 * </ap-nav-bar>
 * </code></pre>
 */
@Component({
  selector: 'ap-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {

  constructor() {}

}
