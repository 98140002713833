import { Component, ElementRef, Input, OnInit, Output, EventEmitter, Optional, SkipSelf, ContentChildren, QueryList, AfterViewInit, ViewChild, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ap-option',
  template: '<div><a [hidden]="!isDisplayed" (click)="select()" href="javascript:void(0);"><ng-content></ng-content></a></div>'
})

export class OptionComponent implements OnInit, AfterViewInit {
  isDisplayed: boolean = true;
  label: string = '';

  @Input() value: any;
  @Output() selectValue = new EventEmitter<any>();

  constructor(@Optional() public optOptionParent: OptOptionComponent, protected elementRef: ElementRef, protected translateService: TranslateService) { }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(e => {
      this.updateLabel();
    });
  }

  ngAfterViewInit(): void {
    this.updateLabel();
  }

  updateLabel() {
    this.label = this.elementRef.nativeElement.innerText;
  }

  select() {
    this.selectValue.next(this.value);
  }

}


@Component({
  selector: 'ap-optoption',
  template: `
    <div *ngIf="isDisplayed">
      <h4>{{ label }}</h4>
      <div style="margin-left: 10px;"><ng-content select="ap-option,ap-optoption"></ng-content></div>
    </div>`
})

export class OptOptionComponent {

  isDisplayed = true;

  @Input() label: string;
  @ContentChildren(OptionComponent, { descendants: true }) optionComponents: QueryList<OptionComponent>;

  constructor(@SkipSelf() @Optional() public optOptionParent: OptOptionComponent) { }

}


@Component({
  selector: 'ap-option-info',
  template: `
  <div [hidden]="!isDisplayed">
    <a style="padding: 5px 10px;border: none;" href="javascript:void(0);" (click)="select()" class="list-group-item">
      <h4 style="font-size: 16px; margin-bottom: 0px;" #la class="list-group-item-heading"><ng-content></ng-content></h4>
      <p style="margin-top: 3px;" class="list-group-item-text">{{ info }}</p>
    </a>
  </div>
  `,
  providers: [{provide: OptionComponent, useExisting: forwardRef(() => OptionInfoComponent)}],
})

export class OptionInfoComponent extends OptionComponent {

  @ViewChild('la') labelContent: ElementRef;

  @Input() info: string;

  constructor(@Optional() public optOptionParent: OptOptionComponent, protected elementRef: ElementRef, protected translateService: TranslateService) {
    super(optOptionParent, elementRef, translateService);
  }

  updateLabel() {
    this.label = this.labelContent?.nativeElement?.innerText;
  }

}
