import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { Page } from "../../commons/page";
import { StopFilter } from "./model/filter/stopFilter";
import { Stop } from "./model/stop";

@Injectable({
    providedIn: 'root'
})

export class StopService {
    private basePath = environment.admin.api.mobilityEngine + '/api/location-manager/v1/topology/stops';

    constructor(
        private httpClient: HttpClient
    ) {}

    public getStopById(id: string): Observable<Stop> {
        if (!id) {
          throw new Error('Parameter id is required for getStopById method');
        }

        return this.httpClient.get<Stop>(`${this.basePath}/${id}`);
    }

    public readStopsByFilters(stopFilter: StopFilter): Observable<Page<Stop>> {

        let params = new HttpParams();

        if (stopFilter.topologyReference) {
            params = params.set('topologyReference', stopFilter.topologyReference);
        }

        if (stopFilter.stopName) {
            params = params.set('stopName', stopFilter.stopName);
        }

        if (stopFilter.stopIds && stopFilter.stopIds.length > 0) {
            params = params.set('stopIds', stopFilter.stopIds.toString());
        }

        params = params.set('page', '0').set('size', '100000');

        return this.httpClient.get<Page<Stop>>(this.basePath, {params});
    }

    public readStopsByName(name: string, topologyReference: string): Observable<Page<Stop>> {
        if (!name) {
            throw new Error('Parameter name is required in readStopsByName method')
        }

        if (!topologyReference) {
            throw new Error('Parameter topology reference is required in readStopByName method');
        }

        const params = new HttpParams()
        .set('stopName', name)
        .set('topologyReference', topologyReference);

        return this.httpClient.get<Page<Stop>>(this.basePath, {params});

    }
}
