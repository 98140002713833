import { Component } from '@angular/core';

@Component({
  selector: 'ap-callout',
  templateUrl: 'callout.component.html',
  styleUrls: ['callout.component.css']
})

export class CalloutComponent {
  constructor() { }
}
