import { Component } from '@angular/core';

@Component({
  selector: 'ap-media-event-content',
  templateUrl: './media-event-content.component.html',
  styleUrls: ['./media-event-content.component.css']
})
export class MediaEventContentComponent {

  constructor() { }

}
