import { Component, Input, OnInit } from '@angular/core';
import { AutocompleteFilterTemplate } from '../../filter-template/autocomplete-filter-template/autocomplete-filter-template.model';
import { AutocompleteFilter } from './autocomplete-filter.model';

@Component({
  selector: 'ap-autocomplete-filter-item',
  templateUrl: './autocomplete-filter-item.component.html',
  styleUrls: ['./autocomplete-filter-item.component.css']
})
export class AutocompleteFilterItemComponent {

  @Input() filter: AutocompleteFilter;

  constructor() { }

  getLabel(): string {
    const template = <AutocompleteFilterTemplate> this.filter.filterTemplate;
    return 'template.options.find(o => (o.value === this.filter.value)).label';
  }

}
